
body {
  font-family: 'Livvic', sans-serif;
  overflow: hidden;
}

@media (prefers-color-scheme: light) {
  body{ 
    background-color: #eeeeee;
    color: #000000;
  }
}

#root {
  width: 100vw;
  height: 100vh;
}

/* @media (prefers-color-scheme: dark) {
  body{
    background-color: #212121;
    color: white;
  }
} */

