.container{
    width: 4em;
    height: 4em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    display: flex;
    margin: 1em 0;
    background: lightgray;
    align-items: center;
    justify-content: center;
    align-self: center;
    /* justify-self: center; */
}

@media (prefers-color-scheme: light) {
    .container{
        background: white;
        color: black;
    }
}

/* @media (prefers-color-scheme: dark) {
    .container{
        background-color: #2c2c2c;
        box-shadow: 0px 4px 4px rgba(54, 16, 16, 0.1);
        color:white;
    }
} */