.profileHeaderCard{
    background: linear-gradient(180deg, #663399 0%, #BC31EA 100%);
    color: white; /* override for light mode */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 100%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profileHeaderCard_image {
    height: 96px;
    width: 96px;
    border-radius: 50%;
    margin-bottom: 1em;
}

@media (prefers-color-scheme: dark) {
    .profileHeaderCard{
       box-shadow: 0px 4px 4px rgba(54, 16, 16, 0.1);
    }
}