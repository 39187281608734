.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  overflow: hidden;
}

.socialLinksHeader {
  font-weight: 400;
  text-align: left;
  margin-top: 1em;
  width: 90%;
}

.socialLinksWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  gap: 5px 5px;
  width: 100%;
}

.appCta {
  background: linear-gradient(180deg, #663399 0%, #bc31ea 100%);
  color: white;
  border-radius: 20px;
  height: 4em;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 2em;
}

.storeButtonsContainer {
  width: 80%;
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.storeButton {
  width: 200px;
}

.playButton {
  width: 230px;
}

@media (prefers-color-scheme: light) {
  .socialLinksHeader {
    color: #663399;
  }
}
